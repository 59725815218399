/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('myAvatarTypingV3.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {/* highlight-line */
    actions['Armature.001|mixamo.com|Layer0.001'].play(); /* highlight-line */
  }); /* highlight-line */

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="Armature">
          <primitive object={nodes.Hips} />
          <skinnedMesh geometry={nodes.Wolf3D_Body.geometry} material={materials['Wolf3D_Body.006']} skeleton={nodes.Wolf3D_Body.skeleton} />
          <skinnedMesh geometry={nodes.Wolf3D_Glasses.geometry} material={materials['Wolf3D_Glasses.006']} skeleton={nodes.Wolf3D_Glasses.skeleton} />
          <skinnedMesh geometry={nodes.Wolf3D_Hair.geometry} material={materials['Wolf3D_Hair.006']} skeleton={nodes.Wolf3D_Hair.skeleton} />
          <skinnedMesh geometry={nodes.Wolf3D_Outfit_Bottom.geometry} material={materials['Wolf3D_Outfit_Bottom.006']} skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton} />
          <skinnedMesh geometry={nodes.Wolf3D_Outfit_Footwear.geometry} material={materials['Wolf3D_Outfit_Footwear.006']} skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton} />
          <skinnedMesh geometry={nodes.Wolf3D_Outfit_Top.geometry} material={materials['Wolf3D_Outfit_Top.006']} skeleton={nodes.Wolf3D_Outfit_Top.skeleton} />
          <skinnedMesh name="EyeLeft" geometry={nodes.EyeLeft.geometry} material={materials['Wolf3D_Eye.006']} skeleton={nodes.EyeLeft.skeleton} morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary} morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences} />
          <skinnedMesh name="EyeRight" geometry={nodes.EyeRight.geometry} material={materials['Wolf3D_Eye.006']} skeleton={nodes.EyeRight.skeleton} morphTargetDictionary={nodes.EyeRight.morphTargetDictionary} morphTargetInfluences={nodes.EyeRight.morphTargetInfluences} />
          <skinnedMesh name="Wolf3D_Head" geometry={nodes.Wolf3D_Head.geometry} material={materials['Wolf3D_Skin.006']} skeleton={nodes.Wolf3D_Head.skeleton} morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences} />
          <skinnedMesh name="Wolf3D_Teeth" geometry={nodes.Wolf3D_Teeth.geometry} material={materials['Wolf3D_Teeth.006']} skeleton={nodes.Wolf3D_Teeth.skeleton} morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('myAvatarTypingV3.glb')
